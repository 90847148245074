import React, { ChangeEvent, useEffect, useRef, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
} from "../../../partials/controls";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../helpers";
import { IconButton } from "@material-ui/core";
import "react-quill/dist/quill.snow.css";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import * as Yup from "yup";
import { api } from "../../../common/api";
import { CKModules } from "../../../common/editor/ckModules";
import CloseRoundedIcon from "@material-ui/icons/CloseRounded";
import moment from "moment";
import { Select } from "../../../common/components/Select";
import { Dialog } from "../../../common/modal";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

interface Params {
  id?: string;
}

interface Values {
  title: string;
  target: string;
  deadline?: string;
  priority?: number;
  description: string;
  banner: string;
  type: string;
  scope: string;
  tag?: string;
  status: boolean;
  videoUrl: string;
}

export const AddEditArticle = () => {
  const history = useHistory();
  const params: Params = useParams();
  const inputFile = useRef<HTMLInputElement | null>(null);
  const [entities, setEntities] = useState<ArticleEntities>();
  const [imgLoaded, setImgLoaded] = useState<boolean>(false);
  const [deleteDialog, setDeleteDialog] = useState<boolean>(false);
  const [data, setData] = useState("");
  const getArticleData = async () => {
    try {
      const { data }: any = await api({
        url: `/article/articleDetails/${params.id}`,
        method: "get",
      });
      if (data.serverResponse.isError) {
        toast.error(data.serverResponse.message);
      } else {
        let item: ArticleEntities = data.result.data;

        setEntities({
          ...item,
          status: item.isActive ? "Active" : "Inactive",
        });
      }
    } catch (err) {
      // @ts-ignore
      err.response && toast.error(err.message);
    }
  };

  const deleteArticle = async () => {
    try {
      const { data }: any = await api({
        url: `/article/remove/${params.id}`,
        method: "delete",
      });
      if (data.serverResponse.isError) {
        toast.error(data.serverResponse.message);
      } else {
      }
      toast.success(data.serverResponse.message);
      history.push("/article");
    } catch (err) {
      // @ts-ignore
      err.response && toast.error(err.message);
    }
  };

  useEffect(() => {
    params.id && getArticleData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleChange = (e, editor) => {
    const data = editor.getData();
    setData(data);
    formik.setFieldValue("description", data);
  };

  const uploadImage = async (e: ChangeEvent) => {
    e.preventDefault();
    if (inputFile.current?.files) {
      const formData = new FormData();
      formData.append("file", inputFile.current.files[0]);
      try {
        const { data }: any = await api({
          url: "/file/file-upload",
          method: "post",
          body: formData,
        });

        if (data.serverResponse.isError) {
          !params.id && formik.setFieldError("banner", "banner is required");
          toast.error(data.serverResponse.message);
        } else {
          formik.setFieldValue("banner", data.data.fileUrl);
          toast.success("Image uploaded");
        }
      } catch (err) {
        formik.setFieldError("banner", "banner is required");
        toast.error("banner not uploaded, please try again");
      }
    }
  };

  const addArticle = async (values: Values) => {
    try {
      let body: any = {
        type: values.type,
        title: values.title,
        description: values.description,
        banner: values.banner,
        scope: values.scope,
        tag: values.tag,
      };
      if (values.type === "VIDEO") {
        body = {
          ...body,
          description: "",
          videoUrl: values.videoUrl,
        };
      }
      if (values.type === "ARTICLE") {
        body = {
          ...body,
          target: values.target,
          deadline: values.deadline,
        };
      }

      if (values.type === "IMAGE") {
        body = {
          ...body,
          priority: values.priority,
        };
      }
      const { data }: any = await api({
        url: "/article",
        method: "post",
        body,
      });
      if (data.serverResponse.isError) {
        toast.error(data.serverResponse.message);
      } else {
        toast.success("Article added");
        history.push("/article");
      }
    } catch (err) {
      // @ts-ignore
      err.response && toast.error(err.message);
    }
  };

  const updateArticle = async (values: Values) => {
    try {
      let body: any = {
        type: values.type,
        title: values.title,
        description: values.description,
        banner: values.banner,
        scope: values.scope,
        tag: values.tag,
        isActive: values.status,
      };

      if (values.type === "VIDEO") {
        body = {
          ...body,
          description: "",
        };
      }
      if (values.type === "ARTICLE") {
        body = {
          ...body,
          deadline: values.deadline,
          target: values.target,
        };
      }
      if (values.type === "IMAGE") {
        body = {
          ...body,
          priority: values.priority,
        };
      }
      const { data }: any = await api({
        url: `/article/articleDetails/${params.id}`,
        method: "put",
        body,
      });
      if (data.serverResponse.isError) {
        toast.error(data.serverResponse.message);
      } else {
        toast.success("Article updated");
        history.push("/article");
      }
    } catch (err) {
      // @ts-ignore
      err.response && toast.error(err.message);
    }
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      title: params.id ? (entities?.title as string) : "",
      target: params.id ? (entities?.target as string) : "",
      deadline: params.id ? (entities?.deadline as string) || "" : "",
      priority: params.id ? (entities?.priority as number) || 0 : 0,
      description: params.id ? (entities?.description as string) : "",
      banner: params.id ? (entities?.banner as string) : "",
      type: params.id ? (entities?.type as string) : "ARTICLE",
      scope: params.id ? (entities?.scope as string) : "PUBLIC", // AGENT
      tag: params.id ? (entities?.tag as string) : "",
      status: params.id ? (entities?.isActive as boolean) : true,
      videoUrl: params.id ? (entities?.videoUrl as string) : "",
    },
    validationSchema: Yup.object({
      title: Yup.string().required("Title is required"),
      target: Yup.string().when("type", {
        is: "ARTICLE",
        then: Yup.string().required("Target is required"),
      }),
      description: Yup.string()
        .when("type", {
          is: "ARTICLE",
          then: Yup.string().required("Description is required"),
        })
        .when("type", {
          is: "IMAGE",
          then: Yup.string().required("Description is required"),
        }),

      priority: Yup.string().when("type", {
        is: "IMAGE",
        then: Yup.string().required("Priority is required"),
      }),
      banner: Yup.string()
        .when("type", {
          is: "ARTICLE",
          then: Yup.string().required("Banner is required"),
        })
        .when("type", {
          is: "IMAGE",
          then: Yup.string().required("Image is required"),
        })
        .when("type", {
          is: "VIDEO",
          then: Yup.string().required("Image is Required"),
        })
        .when("type", {
          is: "" || undefined,
          then: Yup.string().required("Banner is required"),
        }),
      videoUrl: Yup.string().when("type", {
        is: "VIDEO",
        then: Yup.string().required("Video URL is Required"),
      }),
      scope: Yup.string().required("Scope is required"),
      type: Yup.string().required("Type is required"),
      tag: Yup.string()
        .when("type", {
          is: "IMAGE",
          then: Yup.string().required("Tag is required"),
        })
        .when("type", {
          is: "VIDEO",
          then: Yup.string().required("Tag is required"),
        }),
    }),
    onSubmit: (values: Values) => {
      params.id ? updateArticle(values) : addArticle(values);
    },
  });

  useEffect(() => {
    if (formik.values.banner === "") {
      setImgLoaded(false);
    }
  }, [formik.values.banner]);
  return (
    <div>
      <Card>
        <CardHeader title={params.id ? "Article Details" : "Add Article"}>
          {params.id ? (
            <button
              type="button"
              className="btn btn-danger align-self-center"
              onClick={() => setDeleteDialog(true)}
            >
              Delete
            </button>
          ) : (
            ""
          )}
        </CardHeader>
        <CardBody>
          {!params.id && (
            <div
              className={
                formik.touched.type && formik.errors.type ? "" : "mb-4 pb-4"
              }
            >
              <div className="row">
                <div
                  className={`col-12 col-sm-2 ${
                    formik.touched.type && formik.errors.type ? "" : "mb-4"
                  }`}
                >
                  <div className="form-check form-check-inline mr-4">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="type"
                      id="article-radio"
                      value="ARTICLE"
                      checked={formik.values.type === "ARTICLE"}
                      onChange={formik.handleChange}
                    />
                    <label
                      className="form-check-label ml-3"
                      htmlFor="article-radio"
                    >
                      Article
                    </label>
                  </div>
                </div>
                <div
                  className={`col-12 col-sm-2 ${
                    formik.touched.type && formik.errors.type ? "" : "mb-4"
                  }`}
                >
                  <div className="form-check form-check-inline mr-4">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="type"
                      id="image-radio"
                      value="IMAGE"
                      checked={formik.values.type === "IMAGE"}
                      onChange={formik.handleChange}
                    />
                    <label
                      className="form-check-label ml-3"
                      htmlFor="image-radio"
                    >
                      Image
                    </label>
                  </div>
                </div>
                <div
                  className={`col-12 col-sm-2 ${
                    formik.touched.type && formik.errors.type ? "" : "mb-4"
                  }`}
                >
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="type"
                      id="video-radio"
                      value="VIDEO"
                      checked={formik.values.type === "VIDEO"}
                      onChange={formik.handleChange}
                      onClick={() => formik.setFieldValue("banner", "")}
                    />
                    <label
                      className="form-check-label ml-3"
                      htmlFor="video-radio"
                    >
                      Video
                    </label>
                  </div>
                </div>
              </div>
              {formik.touched.type && formik.errors.type ? (
                <div className="text-danger mt-1 ml-1 mb-4 pb-4">
                  {formik.errors.type}
                </div>
              ) : null}
            </div>
          )}

          <div className="row mt-4">
            <div className="col-12 col-sm-2 mb-4 mb-sm-0  ws-nowrap">Title</div>
            <div className="col-4">
              <div className="form-group">
                <input
                  type="text"
                  className="form-control"
                  id="title"
                  placeholder="Add title"
                  name="title"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.title}
                />
                {formik.touched.title && formik.errors.title ? (
                  <div className="text-danger mt-1 ml-1">
                    {formik.errors.title}
                  </div>
                ) : null}
              </div>
            </div>
          </div>

          {formik.values.type !== "VIDEO" && (
            <div className="row mt-4">
              <div className="col-12 col-sm-2 mb-4 mb-sm-0  ws-nowrap">
                {formik.values.type === "ARTICLE" ? "Deadline" : "Priority"}
              </div>
              <div className="col-10 col-sm-4">
                <div className="form-group">
                  {formik.values.type === "ARTICLE" ? (
                    <>
                      <input
                        type="date"
                        className="form-control"
                        name="deadline"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={moment(
                          new Date(formik.values.deadline).toUTCString()
                        ).format("YYYY-MM-DD")}
                      />
                      {formik.touched.deadline && formik.errors.deadline ? (
                        <div className="text-danger mt-1 ml-1">
                          {formik.errors.deadline}
                        </div>
                      ) : null}
                    </>
                  ) : (
                    <>
                      <input
                        type="number"
                        className="form-control"
                        min={0}
                        name="priority"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.priority}
                      />
                      {formik.touched.priority && formik.errors.priority ? (
                        <div className="text-danger mt-1 ml-1">
                          {formik.errors.priority}
                        </div>
                      ) : null}
                    </>
                  )}
                </div>
              </div>
            </div>
          )}

          {formik.values.type === "ARTICLE" && (
            <>
              {" "}
              <div className="row mt-4 pt-4 ">
                <div className="col-12 col-sm-2 mb-4 mb-sm-0  ws-nowrap">
                  Target
                </div>
                <div className="col-4">
                  <div>
                    <input
                      type="text"
                      className="form-control"
                      id="target"
                      placeholder="Target"
                      name="target"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.target}
                    />
                    {formik.touched.target && formik.errors.target ? (
                      <div className="text-danger mt-1 ml-1">
                        {formik.errors.target}
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            </>
          )}

          {formik.values.type !== "VIDEO" && (
            <>
              <div className="row mt-4 pt-4 ">
                <div className="col-12 col-sm-2 mb-4 mb-sm-0  ws-nowrap">
                  Description
                </div>
                <div className="col-10 ">
                  <div className="">
                    <div>
                      <CKEditor
                        editor={ClassicEditor}
                        config={CKModules}
                        className="ck"
                        data={formik.values.description || ""}
                        onChange={handleChange}
                        onBlur={() =>
                          formik.setFieldTouched("description", true)
                        }
                      />
                      {formik.touched.description &&
                      formik.errors.description ? (
                        <div className="text-danger mt-1 ml-1">
                          {formik.errors.description}
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div></div>
                </div>
              </div>
            </>
          )}
          <div className="row mt-2 pt-4">
            <div className="col-12 col-sm-2 mb-4 mb-sm-0  ws-nowrap">
              {formik.values.type === "ARTICLE" ? "Banner" : "Image"}
            </div>
            <div className="col-10 ">
              {formik.values.banner && (
                <div className="w-50 h-100">
                  <input
                    className="w-100 h-100"
                    type="file"
                    onChange={uploadImage}
                    ref={inputFile}
                    hidden
                    name="upload-image"
                    id="upload-image"
                    accept="image/jpg, image/png, image/jpeg"
                  />
                  <div className="position-relative fit-content">
                    <img
                      src={formik?.values?.banner || ""}
                      alt=""
                      className="img-fluid"
                      onClick={() => {
                        let input = document.getElementById(
                          "upload-image"
                        )! as HTMLInputElement;
                        input.value = "";
                        inputFile.current?.click();
                      }}
                      onLoad={() => setImgLoaded(true)}
                      onError={({ currentTarget }) => {
                        currentTarget.onerror = null; // prevents looping
                        currentTarget.src = "/media/bg/image-not-found.jpeg";
                      }}
                    />
                    {imgLoaded && (
                      <IconButton
                        disableRipple
                        disableFocusRipple
                        className="position-absolute delete-image-btn"
                        onClick={() => formik.setFieldValue("banner", "")}
                      >
                        <CloseRoundedIcon />
                      </IconButton>
                    )}
                  </div>
                </div>
              )}
              {!formik.values.banner && (
                <div className="p-1 upload-image mt-4 ">
                  <input
                    className="w-50 h-100"
                    type="file"
                    onChange={uploadImage}
                    ref={inputFile}
                    hidden
                    name="upload-image"
                    id="upload-image"
                    accept="image/jpg, image/png, image/jpeg"
                  />
                  <IconButton
                    disableRipple
                    disableFocusRipple
                    onClick={() => {
                      let input = document.getElementById(
                        "upload-image"
                      )! as HTMLInputElement;
                      input.value = "";
                      inputFile.current?.click();
                    }}
                    className="image-add-icon"
                  >
                    <SVG
                      src={toAbsoluteUrl(
                        "/media/svg/icons/Files/File-plus.svg"
                      )}
                    />
                  </IconButton>
                </div>
              )}
              {formik.touched.banner && formik.errors.banner ? (
                <div className="text-danger mb-0 mt-4 pt-1">
                  {formik.errors.banner}
                </div>
              ) : null}
            </div>
          </div>

          {formik.values.type === "VIDEO" && (
            <div className="mt-4 pt-4">
              <div className="row">
                <div className="col-12 col-sm-2 mb-4 mb-sm-0  ws-nowrap">
                  YouTube Link
                </div>
                <div className="col-10">
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      id="youtube_link"
                      placeholder="Add youtube link"
                      name="videoUrl"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.videoUrl}
                    />
                    {formik.touched.videoUrl && formik.errors.videoUrl ? (
                      <div className="text-danger mt-1 ml-1">
                        {formik.errors.videoUrl}
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          )}
          {formik.values.type !== "ARTICLE" && (
            <div className="mt-4 pt-4">
              <div className="row mt-4 pt-4">
                <div className="col-12 col-sm-2 mb-4 mb-sm-0  ws-nowrap">
                  Tag
                </div>
                <div className="col-10">
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      id="tag"
                      placeholder="Add tag"
                      name="tag"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.tag}
                    />
                    {formik.touched.tag && formik.errors.tag ? (
                      <div className="text-danger mt-1 ml-1">
                        {formik.errors.tag}
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          )}
          <div className={`${formik.values.type !== "ARTICLE" ? "" : "mt-4"}`}>
            <div className="row">
              {/* <div
                className={`col-4 col-sm-2 mt-4 ${
                  formik.values.type !== "ARTICLE" ? "" : "pt-4"
                }`}
              >
                <div className="form-check form-check-inline mr-4">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="scope"
                    id="agent-only"
                    value="AGENT"
                    checked={formik.values.scope === "AGENT"}
                    onChange={formik.handleChange}
                  />
                  <label
                    className="form-check-label ml-3 ws-nowrap"
                    htmlFor="agent-only"
                  >
                    Agent Only
                  </label>
                </div>
              </div> */}
              {formik.values.type === "ARTICLE" && (
                <div
                  className={`col-4 col-sm-2 mt-4 ${
                    formik.values.type !== "ARTICLE" ? "" : "pt-4"
                  }`}
                >
                  <div className="form-check form-check-inline mr-4">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="scope"
                      id="public"
                      value="PUBLIC"
                      checked={formik.values.scope === "PUBLIC"}
                      onChange={formik.handleChange}
                    />
                    <label className="form-check-label ml-3" htmlFor="public">
                      Public
                    </label>
                  </div>
                </div>
              )}
            </div>
            {formik.touched.scope && formik.errors.scope ? (
              <div className="text-danger mt-1 ml-1">{formik.errors.scope}</div>
            ) : null}
          </div>

          {params?.id && (
            <div className="row mt-4 pt-4">
              <div className="col-12 col-sm-2 mb-4 mb-sm-0  ws-nowrap">
                Status
              </div>
              <div className="col-10 col-sm-4">
                <Select
                  value={formik.values.status}
                  setValue={(value: string) =>
                    formik.setFieldValue("status", value)
                  }
                  options={[
                    { label: "Active", value: true },
                    { label: "Inactive", value: false },
                  ]}
                />
              </div>
            </div>
          )}
        </CardBody>
        {deleteDialog && (
          <Dialog
            name="delete"
            show={params.id}
            onHide={() => setDeleteDialog(false)}
            action={deleteArticle}
            headerText="Are you sure?"
            bodyText="Confirm to delete the content?"
            loadingText="Removing member from blacklist..."
            actionText="Delete"
            actionStyle="danger"
          />
        )}
        <CardFooter>
          <div className="d-flex flex-wrap align-items-center justify-content-between">
            <div className="mt-4 mt-sm-0">
              <button
                className="btn btn-success mr-2 mx-sm-2"
                onClick={() => formik.handleSubmit()}
              >
                {params?.id ? "Save" : "Add"}
              </button>
            </div>
            <div className="mt-4 mt-sm-0">
              <Link className="btn btn-primary" to="/article">
                Back
              </Link>
            </div>
          </div>
        </CardFooter>
      </Card>
    </div>
  );
};
