/* eslint-disable jsx-a11y/role-supports-aria-props */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl, checkIsActive } from "../../../../helpers";
import { store } from "../../../../redux/store";
import { logout } from "../../../../common/api/logout";

export function AsideMenuList({ layoutProps }) {
  const location = useLocation();
  const getMenuItemActive = (url, hasSubmenu = false) => {
    return checkIsActive(location, url)
      ? ` ${!hasSubmenu &&
      "menu-item-active"} menu-item-open menu-item-not-hightlighted`
      : "";
  };
  const state = store.getState();

  const specialConfigAccess = state.user.specialConfigAccess;
  return (
    <>
      {/* begin::Menu Nav */}
      <ul className={`menu-nav ${layoutProps.ulClasses}`}>
        {/*begin::1 Level*/}

        <li
          className={`menu-item ${getMenuItemActive("/article", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/article">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Shopping/Box2.svg")} />
            </span>
            <span className="menu-text">ARTICLE</span>
          </NavLink>
        </li>
        {/*end::1 Level*/}

        {/* APPROVAL */}
        {/*begin::1 Level*/}
        <li
          className={`menu-item menu-item-submenu ${getMenuItemActive(
            "/approval",
            true
          )}`}
          aria-haspopup="true"
          data-menu-toggle="hover"
        >
          <NavLink className="menu-link menu-toggle" to="#">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Shopping/Box2.svg")} />
            </span>
            <span className="menu-text">APPROVAL</span>
            <i className="menu-arrow" />
          </NavLink>
          <div className="menu-submenu ">
            <i className="menu-arrow" />
            <ul className="menu-subnav">
              {/* Agent Registration */}
              {/*begin::2 Level*/}
              {/* <li
                className={`menu-item menu-item-submenu ${getMenuItemActive(
                  "/approval/agent-registration",
                  true
                )}`}
                aria-haspopup="true"
                data-menu-toggle="hover"
              >
                <NavLink
                  className="menu-link menu-toggle"
                  to="/approval/agent-registration"
                >
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">Agent Registration</span>
                </NavLink>
              </li> */}
              {/*end::2 Level*/}

              {/* Agent Upgrade */}
              {/*begin::2 Level*/}
              {/* <li
                className={`menu-item menu-item-submenu ${getMenuItemActive(
                  "/approval/agent-upgrade",
                  true
                )}`}
                aria-haspopup="true"
                data-menu-toggle="hover"
              >
                <NavLink
                  className="menu-link menu-toggle"
                  to="/approval/agent-upgrade"
                >
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">Change Grade</span>
                </NavLink>
              </li> */}
              {/*end::2 Level*/}

              {/* Cashout */}
              {/*begin::2 Level*/}
              {/* <li
                className={`menu-item menu-item-submenu ${getMenuItemActive(
                  "/approval/cashout",
                  true
                )}`}
                aria-haspopup="true"
                data-menu-toggle="hover"
              >
                <NavLink
                  className="menu-link menu-toggle"
                  to="/approval/cashout"
                >
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">Cashout</span>
                </NavLink>
              </li> */}
              {/*end::2 Level*/}

              {/* Gift Redemption */}
              {/*begin::2 Level*/}
              <li
                className={`menu-item menu-item-submenu ${getMenuItemActive(
                  "/approval/gift-redemption",
                  true
                )}`}
                aria-haspopup="true"
                data-menu-toggle="hover"
              >
                <NavLink
                  className="menu-link menu-toggle"
                  to="/approval/gift-redemption"
                >
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">Gift Redemption</span>
                </NavLink>
              </li>
              {/*end::2 Level*/}

              {/* Monthly Agent Credit */}
              {/*begin::2 Level*/}
              {/* <li
                className={`menu-item menu-item-submenu ${getMenuItemActive(
                  "/approval/monthly-agent-credit",
                  true
                )}`}
                aria-haspopup="true"
                data-menu-toggle="hover"
              >
                <NavLink
                  className="menu-link menu-toggle"
                  to="/approval/monthly-agent-credit"
                >
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">Monthly Agent Credit</span>
                </NavLink>
              </li> */}
              {/*end::2 Level*/}
            </ul>
          </div>
        </li>
        {/*end::1 Level*/}

        {/* Member */}
        {/*begin::1 Level*/}
        <li
          className={`menu-item menu-item-submenu
            ${getMenuItemActive("/member", true)}
          `}
          aria-haspopup="true"
          data-menu-toggle="hover"
        >
          <NavLink className="menu-link menu-toggle" to="#">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Shopping/Box2.svg")} />
            </span>
            <span className="menu-text">MEMBER</span>
            <i className="menu-arrow" />
          </NavLink>
          <div className="menu-submenu ">
            <i className="menu-arrow" />
            <ul className="menu-subnav">
              {/* Admin */}
              {/*begin::2 Level*/}
              <li
                className={`menu-item ${getMenuItemActive(
                  "/member/admin",
                  false
                )}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/member/admin">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">Admin</span>
                </NavLink>
              </li>
              {/*end::2 Level*/}

              {/* Agent */}
              {/*begin::2 Level*/}
              {/* <li
                className={`menu-item ${getMenuItemActive(
                  "/member/agent",
                  false
                )}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/member/agent">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">Agent</span>
                </NavLink>
              </li> */}
              {/*end::2 Level*/}

              {/* Customer */}
              {/*begin::2 Level*/}
              <li
                className={`menu-item ${getMenuItemActive(
                  "/member/customer",
                  false
                )}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/member/customer">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">Customer</span>
                </NavLink>
              </li>
              {/*end::2 Level*/}
            </ul>
          </div>
        </li>

        {/*begin::1 Level*/}
        {/* <li
          className={`menu-item ${getMenuItemActive("/grade-level", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/grade-level">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Shopping/Box2.svg")} />
            </span>
            <span className="menu-text">GRADE LEVEL</span>
          </NavLink>
        </li> */}
        {/*end::1 Level*/}

        {/*begin::1 Level*/}
        <li
          className={`menu-item ${getMenuItemActive("/blacklist", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/blacklist">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Shopping/Box2.svg")} />
            </span>
            <span className="menu-text">BLACKLIST</span>
          </NavLink>
        </li>
        {/*end::1 Level*/}

        {/*begin::1 Level*/}
        <li
          className={`menu-item ${getMenuItemActive("/product", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/product">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Shopping/Box2.svg")} />
            </span>
            <span className="menu-text">PRODUCT</span>
          </NavLink>
        </li>
        {/*end::1 Level*/}

        {/*begin::1 Level*/}
        {/* <li
          className={`menu-item ${getMenuItemActive("/qr-code", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/qr-code">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Shopping/Box2.svg")} />
            </span>
            <span className="menu-text">GEN QR CODE</span>
          </NavLink>
        </li> */}
        {/*end::1 Level*/}

        {/*begin::1 Level*/}
        <li
          className={`menu-item ${getMenuItemActive("/eshop", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/eshop">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Shopping/Box2.svg")} />
            </span>
            <span className="menu-text">ESHOP</span>
          </NavLink>
        </li>
        {/*end::1 Level*/}

        {/*begin::1 Level*/}
        {/* <li
          className={`menu-item ${getMenuItemActive("/transmission", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/transmission">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Shopping/Box2.svg")} />
            </span>
            <span className="menu-text">TRANSMISSION</span>
          </NavLink>
        </li> */}
        {/*end::1 Level*/}

        {/*begin::1 Level*/}
        {/* <li
          className={`menu-item ${getMenuItemActive("/grade-info", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/grade-info">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Shopping/Box2.svg")} />
            </span>
            <span className="menu-text">GRADE INFO</span>
          </NavLink>
        </li> */}
        {/*end::1 Level*/}

        {/*begin::1 Level*/}
        {/* <li
          className={`menu-item ${getMenuItemActive("/sales-target", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/sales-target">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Shopping/Box2.svg")} />
            </span>
            <span className="menu-text">SALES TARGET</span>
          </NavLink>
        </li> */}
        {/*end::1 Level*/}

        {/*begin::1 Level*/}
        <li
          className={`menu-item ${getMenuItemActive(
            "/push-notification",
            false
          )}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/push-notification">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Shopping/Box2.svg")} />
            </span>
            <span className="menu-text">PUSH NOTIFICATION</span>
          </NavLink>
        </li>
        {/*end::1 Level*/}

        {/*begin::1 Level*/}
        {/* <li
          className={`menu-item ${getMenuItemActive(
            "/item-maintenance",
            false
          )}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/item-maintenance">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Shopping/Box2.svg")} />
            </span>
            <span className="menu-text">ITEM MAINTENANCE</span>
          </NavLink>
        </li> */}
        {/*end::1 Level*/}

        {/* CONTENT MANAGEMENT */}
        {/*begin::1 Level*/}
        {/* <li
          className={`menu-item menu-item-submenu
            ${getMenuItemActive("/about-us", true)}
            ${getMenuItemActive("/contact-us", true)}
          `}
          aria-haspopup="true"
          data-menu-toggle="hover"
        >
          <NavLink className="menu-link menu-toggle" to="#">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Shopping/Box2.svg")} />
            </span>
            <span className="menu-text">CONTENT MANAGEMENT</span>
            <i className="menu-arrow" />
          </NavLink>

          <div className="menu-submenu ">
            <i className="menu-arrow" />
            <ul className="menu-subnav"> */}
        {/* About Us */}
        {/*begin::2 Level*/}
        {/* <li
                className={`menu-item ${getMenuItemActive("/about-us", false)}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/about-us">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">About Us</span>
                </NavLink>
              </li> */}
        {/*end::2 Level*/}

        {/* Contact Us */}
        {/*begin::2 Level*/}
        {/* <li
                className={`menu-item ${getMenuItemActive(
                  "/contact-us",
                  false
                )}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/contact-us">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">Contact Us</span>
                </NavLink>
              </li> */}
        {/*end::2 Level*/}
        {/* </ul>
          </div>
        </li> */}
        {/*end::1 Level*/}

        {/* CONTACT US */}
        {/*begin::1 Level*/}
        <li
          className={`menu-item ${getMenuItemActive("/contact-us", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/contact-us">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Shopping/Box2.svg")} />
            </span>
            <span className="menu-text">CONTACT US</span>
          </NavLink>
        </li>
        {/*end::1 Level*/}

        {/* SETTINGS */}
        {/*begin::1 Level*/}
        <li
          className={`menu-item menu-item-submenu
            ${getMenuItemActive("/privacy-policy", true)}
            ${getMenuItemActive("/terms-and-conditions", true)}
            ${getMenuItemActive("/agent-terms-and-conditions", true)}
            ${getMenuItemActive("/company-account", true)}
            ${getMenuItemActive("/cash-bank", true)}
            ${getMenuItemActive("/credit-value", true)}
            ${getMenuItemActive("/special-config", true)}
            ${getMenuItemActive("/official-site-url", true)}
          `}
          aria-haspopup="true"
          data-menu-toggle="hover"
        >
          <NavLink className="menu-link menu-toggle" to="#">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Shopping/Box2.svg")} />
            </span>
            <span className="menu-text">SETTINGS</span>
            <i className="menu-arrow" />
          </NavLink>
          <div className="menu-submenu ">
            <i className="menu-arrow" />
            <ul className="menu-subnav">
              {/* App Admin */}
              {/*begin::2 Level*/}
              <li
                className={`menu-item ${getMenuItemActive(
                  "/app-admin",
                  false
                )}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/app-admin">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">App Admin</span>
                </NavLink>
              </li>
              {/*end::2 Level*/}

              {/* Privacy Policy */}
              {/*begin::2 Level*/}
              <li
                className={`menu-item ${getMenuItemActive(
                  "/privacy-policy",
                  false
                )}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/privacy-policy">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">Privacy Policy</span>
                </NavLink>
              </li>
              {/*end::2 Level*/}

              {/* Terms & Conditions */}
              {/*begin::2 Level*/}
              <li
                className={`menu-item ${getMenuItemActive(
                  "/terms-and-conditions",
                  false
                )}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/terms-and-conditions">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">Terms & Conditions</span>
                </NavLink>
              </li>
              {/*end::2 Level*/}

              {/* Agent Terms & Conditions */}
              {/*begin::2 Level*/}
              {/* <li
                className={`menu-item ${getMenuItemActive(
                  "/agent-terms-and-conditions",
                  false
                )}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/agent-terms-and-conditions">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">Agent Terms & Conditions</span>
                </NavLink>
              </li> */}
              {/*end::2 Level*/}

              {/* Company Account */}
              {/*begin::2 Level*/}
              <li
                className={`menu-item ${getMenuItemActive(
                  "/company-account",
                  false
                )}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/company-account">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">Company Account</span>
                </NavLink>
              </li>
              {/*end::2 Level*/}

              {/* Cash Bank */}
              {/*begin::2 Level*/}
              {/* <li
                className={`menu-item ${getMenuItemActive(
                  "/cash-bank",
                  false
                )}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/cash-bank">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">Cash Bank</span>
                </NavLink>
              </li> */}
              {/*end::2 Level*/}

              {/* Credit Value */}
              {/*begin::2 Level*/}
              {/* <li
                className={`menu-item ${getMenuItemActive(
                  "/credit-value",
                  false
                )}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/credit-value">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">Credit Value</span>
                </NavLink>
              </li> */}
              {specialConfigAccess && <li
                className={`menu-item ${getMenuItemActive(
                  "/special-config",
                  false
                )}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/special-config">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">Special Config</span>
                </NavLink>
              </li>}
              {/*end::2 Level*/}

              {/* Official Site Url */}
              {/*begin::2 Level*/}
              <li
                className={`menu-item ${getMenuItemActive(
                  "/official-site-url",
                  false
                )}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/official-site-url">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">Official Stie Url</span>
                </NavLink>
              </li>
              {/*end::2 Level*/}
            </ul>
          </div>
        </li>
        {/*end::1 Level*/}

        {/*begin::1 Level*/}
        <li
          className={`menu-item ${getMenuItemActive("#", false)}`}
          aria-haspopup="true"
          onClick={logout}
        >
          <NavLink className="menu-link" to="#">
            {/* <span className="svg-icon menu-icon"> */}
            <span className="menu-icon">
              {/* <SVG src={toAbsoluteUrl("/media/svg/icons/Shopping/Box2.svg")} /> */}
              <i className="fa fa-power-off" aria-hidden="true"></i>
            </span>
            <span className="menu-text">LOG OUT</span>
          </NavLink>
        </li>
        {/*end::1 Level*/}
      </ul>
      {/* end::Menu Nav */}
    </>
  );
}
